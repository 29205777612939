<template>
  <Page full>
    <Grid
      type="stage"
      title="Custom construction stages"
      :multiple="true"
      :permanent-filters="{
        company_id: $store.state.session.company.company_id
      }"
      :order="[['stage_time_created', 'DESC']]"
      :visible="['stage_name', 'stage_desc', 'stage_keywords', 'after_stage_name']"
      :isMain="true"
    />
  </Page>
</template>

<script>
import Grid from '../ui/Grid/Grid.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  name: 'StagesPage',
  components: {
    Grid
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
